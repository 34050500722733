import React, { Component} from "react";

export default class Endmessage extends Component {
    render() {
        return (
            <div className="this-is-the-end">
                <strong>You have reached the end of Vinstagram!</strong>
            </div>
        )
    }
};