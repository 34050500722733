import React, { Component } from "react";

import axios from "axios";

import Navbar from './components/navbar'
import DiscoverComponent from './components/discover'


class Discover extends Component {
	constructor() {
		super();
		this.state = { 
			posts: [],
		};
	};

	componentDidMount() {
		axios.get("https://api.zaplog.pro/v1/links").then(response => this.setState({ 
			posts: response.data,			
		}));
	}

	render() {
		return (
		<>
			<Navbar></Navbar>
			<div className="container">
                <div className="content-wrapper">
                    <DiscoverComponent posts={this.state.posts}/>
                </div>
			</div>
		</>
		);
	}
}

export default Discover;