import React, { Component} from "react";

let uri = process.env.REACT_APP_BASE_URL;

export default class Sidebar extends Component {
    render() {
        return (
            <div className="content-sidebar">
                <div className="sidebar-head">
                    <img className="sidebar-head-logo" src={uri + this.props.user.picture.formats.thumbnail.url} width="56" height="56" alt="Profile"></img>
                    <div className="sidebar-head-names">
                        <div>
                            <a className="sidebar-head-profile" href="/profile"><strong >{this.props.user.username}</strong></a>
                        </div>
                        <div>
                            <div className="first-name">{this.props.user.firstname}</div>
                        </div>
                    </div>
                    <a className="switch" href="TODO">
                        Switch
                    </a>
                </div>

                <div className="sidebar-suggestions">
                    <div className="sidebar-suggestions-header">
                    <strong className="sidebar-suggestions-title">Suggestions for you</strong>
                    <a className="suggestions-see-all" href="TODO"><strong><small>See all</small></strong></a>
                    </div>
                    <div className="suggestions-feed">
                        {this.props.suggestions.map(suggestion => (
                            <a href={suggestion.attributes.url} key={suggestion.id} className="suggestions-item">
                                    <img className="rounded-circle" src={uri + suggestion.attributes.logo.data.attributes.formats.small.url} width="40" height="40" alt=""/>
                                    <div className="suggestions-text">
                                        <strong>{suggestion.attributes.name}</strong> 
                                        <small className="followed-by">Followed by ... + {Math.round(Math.random() * 10)} more</small>
                                    </div>
                                
                                <div className="suggestions-follow" href={suggestion.attributes.url}>
                                    Follow
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="sidebar-info">
                    <small>
                        <a href="https://vincentvanderwal.nl">About me</a> ·&nbsp;
                        <a href="https://vincentvanderwal.nl">Help</a> ·&nbsp;
                        <a href="https://vincentvanderwal.nl">Press</a> ·&nbsp; 
                        <a href="https://vincentvanderwal.nl">API</a> ·&nbsp;
                        <a href="https://vincentvanderwal.nl">Jobs</a> ·&nbsp; 
                        <a href="https://vincentvanderwal.nl">Privacy</a> ·&nbsp; 
                        <a href="https://vincentvanderwal.nl">Terms</a> ·&nbsp; 
                        <a href="https://vincentvanderwal.nl">Locations</a> ·&nbsp; 
                        <a href="https://vincentvanderwal.nl">Language</a>
                    </small>
                </div>

                <div className="sidebar-info">
                    <small>
                        <span className="copyleft">&copy;</span> {new Date().getFullYear()} VINSTAGRAM FROM VINNIE
                    </small>
                </div>
            </div>
        )
    }
};


