import React from "react";

import useIntersectionObserver from "../hooks/use-intersection-observer";
import Image from "./image";

import heartFullLogo from '../assets/fontawesome/svgs/solid/heart.svg';

const ImageContainer = props => {
    const ref = React.useRef();
    const [isVisible, setIsVisible] = React.useState(false);
    useIntersectionObserver({
        target: ref,
        rootMargin: "400px",
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                observerElement.unobserve(ref.current);
            }
        }
    });

    const aspectRatio = (props.post.post_images.data[0].attributes.postimageh / props.post.post_images.data[0].attributes.postimagew) * 100;
    return (
        <div
            ref={ref}
            onClick={(e) => {props.likefunction(e, false, props.postid)}}
            className="image-container"
            style={{ paddingBottom: `${aspectRatio}%`, height: "0px" }}>
            {isVisible && (
                <Image post={props.post} />
            )}
            <div className="heart-filled-image" id={"heart_" + props.postid}>
                <img alt="Like Logo" id="heartLogo" src={ heartFullLogo } width="100" height="100"></img>
            </div>
        </div>
    );
};
  
export default ImageContainer;