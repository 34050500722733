import React from "react";
import { createRoot } from 'react-dom/client';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
//import { PageTransition } from '@steveeeie/react-page-transition';

import { Provider } from 'react-redux'
import store from './store'

import './css/vinstagram.scss';
import './assets/fontawesome/css/all.css';

import Home from './App';
import Profile from './Profile';
import Discover from './Discover';
import Comments from './Comments';


const container = document.getElementById('root');
const root = createRoot(container);

function CustomRouter() {
    const location = useLocation();

    // <PageTransition
    //     preset="moveToLeftFromRight"
    //     transitionKey={location.pathname}
    // >

    return <Routes>
            <Route path="/" element={<Provider store={store}><Home/></Provider>} />
            <Route path="/profile" element={ <Profile/> } />
            <Route path="/discover" element={ <Discover/> } />
            <Route path="/comments" element={ <Comments location={location}/> } />
        </Routes>
}


root.render(
    <BrowserRouter>
        <CustomRouter/>
    </BrowserRouter>
);
   

