import React from "react";

import { Link, useLocation } from "react-router-dom";

import vinstaLogo from '../assets/logos/vinstagram.png';
import homeLogo from '../assets/fontawesome/svgs/solid/home.svg';
import altHomeLogo from '../assets/fontawesome/svgs/light/home.svg';
import paperPlane from '../assets/fontawesome/svgs/light/paper-plane.svg';
import compassLogo from '../assets/fontawesome/svgs/light/compass.svg';
import heartLogo from '../assets/fontawesome/svgs/light/heart.svg';
import userLogo from '../assets/fontawesome/svgs/light/user-circle.svg';
import userActiveLogo from '../assets/fontawesome/svgs/solid/user-circle.svg';

import searchLogo from '../assets/fontawesome/svgs/light/search.svg';
import planeLogo from '../assets/fontawesome/svgs/light/paper-plane.svg';
import plusLogo from '../assets/fontawesome/svgs/light/plus-square.svg';
import moreLogo from '../assets/fontawesome/svgs/light/angle-down.svg';
import playLogo from '../assets/fontawesome/svgs/light/play-circle.svg';
import shopLogo from '../assets/fontawesome/svgs/light/shopping-bag.svg';

import "../css/components/navbar.scss"

let uri = process.env.REACT_APP_BASE_URL;

const Navbar = props => {
    const location = useLocation();

    function switchDark() {
        let darkmode = localStorage.getItem('darkmode');
        if (darkmode !== null) {
            if (darkmode === 'true') {
                localStorage.setItem('darkmode', false);
            } else {
                localStorage.setItem('darkmode', true);
            }
        } else {
            localStorage.setItem('darkmode', true);
        }
        window.location.reload();
    }
    
    return (
        <>
            <nav className="navbar-mobile">
                <div className="container navbar-inside">
                    <div className="nav-left">
                        <Link to="/"><img alt="Logo" src={ vinstaLogo } width="auto" height="50"></img></Link>
                    </div>

                    <div className="nav-right">
                        <img alt="Add Logo" src={ plusLogo } width="24" height="24"></img>
                        <img id="heart_logo" src={ heartLogo } width="24" height="24" alt="Likes"/> {/* heart */}
                        <img onClick={(e) => {switchDark()}} alt="Share Logo" src={ planeLogo } width="24" height="24"></img>
                    </div>
                </div>
            </nav>

            <nav className="navbar">
                <div className="container navbar-inside">
                    <div className="navbar-content">

                        <div className="nav-left">
                            <Link to="/"><img alt="Logo" src={ vinstaLogo } width="auto" height="40"></img></Link>
                            <img className="more-logo" alt="More Logo" src={ moreLogo } width="22" height="22"></img>
                        </div>

                        <div className="nav-center">
                            <div className="nav-search">
                                <div >
                                <i className="far fa-search search-logo"></i>
                                </div>
                                <input className="search-text" placeholder="Search">
                                
                                </input>
                            </div>
                        </div>

                        <div className="nav-right">
                            {location.pathname !== "/" ?
                                <Link to="/"><img src={ altHomeLogo } width="27" height="27" alt="Home"/></Link>
                                :
                                <Link to="/"><img src={ homeLogo } width="26" height="26" alt="Home"/></Link>
                            }

                            <img className="desktop" onClick={(e) => {switchDark()}} src={ paperPlane } width="24" height="24" alt="Share"/>

                            <img className="desktop" src={ plusLogo } width="25" height="25" alt="Plus"/>
                            <a   className="desktop" href="https://cms.vinstagram.nl/admin"><img src={ compassLogo } width="25" height="25" alt="Discover"/></a>
                            <img className="desktop" id="heartLogo" src={ heartLogo } width="25" height="25" alt="Likes"/>
                            
                            <img className="mobile" src={ searchLogo } width="24" height="24" alt="Search"/>
                            <img className="mobile" href="https://cms.vinstagram.nl/admin" src={ playLogo } width="24" height="24" alt="Play"/>
                            <img className="mobile" src={ shopLogo } width="24" height="24" alt="Shop"/>

                            <Link to="/profile">
                                <img className="desktop sidebar-head-logo" src={uri + props.user.picture.formats.thumbnail.url} width="25" height="25" alt="Profile"></img>
                                { location.pathname !== "/profile" ?
                                    <img className="mobile" href="/profile" id="userLogo" src={ userLogo } width="24" height="24" alt="User"/>
                                    :
                                    <img className="mobile" href="/profile" id="userLogo" src={ userActiveLogo } width="24" height="24" alt="User"/>
                                }
                            </Link>
                        </div> 
                    </div>
                </div>
            </nav>
        </>
    )
};

export default Navbar;