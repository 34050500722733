import React, { Component} from "react";

import cameraLogo from '../assets/fontawesome/svgs/brands/instagram-colour.svg';


import "../css/components/loading.scss"

export default class LoadingScreen extends Component {
    constructor() {
		super();
		this.state = { 
        
		};
	};

    render() {
        return (
            <div className={this.props.fadeout ? "loading-screen fade-out" : "loading-screen"}>
                <div className={this.props.fadeout ? "loading-middle fade-out-fast" : "loading-middle"}>
                    <img alt="Camera Logo" id="cameraLogo" src={ cameraLogo } width="80" height="80"></img>

                </div>

                <div className={this.props.fadeout ? "loading-bottom fade-out-fast" : "loading-bottom"}>
                    <span>by</span>
                    <strong className="gradient-text">Vinnie</strong>
                </div>
            </div>
        )
    }
};
