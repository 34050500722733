import React, { Component} from "react";

let uri = process.env.REACT_APP_BASE_URL;
export default class Statusbar extends Component {
    render() {
        return (
            <div className="status-bar">
                {this.props.users.map(user => (
                    <a href={user.instaurl} key={user.username} className="status-item">
                        <div className="rounded-circle border status-picture"><img className="rounded-circle border status-picture-img" src={user.picture.formats ? uri + user.picture.formats.thumbnail.url : uri + user.picture.url} width="58" height="58" alt={user.username}></img></div>
                        <small className="status-name">{user.username.length > 10 ?  user.username.slice(0, 10) + "..." : user.username}</small>
                    </a>
                ))}
            </div>
        )
    }
};


