import React, { Component} from "react";

import moment from 'moment';

import axios from "axios";

import { Link } from "react-router-dom";

import heartLogo from '../assets/fontawesome/svgs/light/heart.svg';
import heartFullLogo from '../assets/fontawesome/svgs/solid/heart.svg';
import commentLogo from '../assets/fontawesome/svgs/light/comment.svg';
import planeLogo from '../assets/fontawesome/svgs/light/paper-plane.svg';
import bookmarkLogo from '../assets/fontawesome/svgs/light/bookmark.svg';
import noPicture from '../assets/fontawesome/svgs/duotone/user-circle.svg';

let uri = process.env.REACT_APP_BASE_URL;
let api_uri = uri + "/api/";

export default class Footer extends Component {
    constructor() {
		super();
		this.state = { 
            comment: "",
            comments: [],
            chosenFriends: [],
            lastFriend: {
                'attributes': {
                    name: '',
                    instaurl: ''
                }
            },
            showall: false,
            translated: false,
		};
        this.showAll = this.showAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postTitleText = React.createRef()
	};


    md5(inputString) {
        var hc="0123456789abcdef";
        function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
        function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
        function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
        function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
        function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
        function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
        function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
        function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
        function sb(x) {
            var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
            for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
            blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
        }
        var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
        for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
            a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
            b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
            c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
            d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
            a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
            b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
            c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
            d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
            a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
            b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
            c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
            d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
            a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
            b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
            c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
            d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
            a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
            b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
            c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
            d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
            a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
            b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
        }
        return rh(a)+rh(b)+rh(c)+rh(d);
    }

    handleChange(event) {
        this.setState({comment: event.target.value});
    }
    
    handleSubmit(event) {
        if (this.state.comment) {
            let name = prompt("What is your name?")
            let answer = prompt("What is Vinnies favourite food?")
            let correctAnswers = [
                '288646301ca4bdcca91e755cfab9340b',
                '7546cef3b7b2c9de09f6974d75473bc6',
                'adee10d6fa6b4a283ff3f3b643557f3d',
                'fea0f1f6fede90bd0a925b4194deac11',
                '3f0cff1d3cc4642e15835d7304d453cc',
                'cfa7156385219d2ca8de678f1d986fea'
            ]
            if (correctAnswers.includes(this.md5(answer.toLowerCase()))) {
                let body = {
                    "content": this.state.comment,
                    "author": {
                        "id": Math.round(Math.random() * 1000),
                        "name": name.toLowerCase(),
                        "email": "1@2.nl",
                        "avatar": noPicture,
                    },
                }

                axios({
                    method: 'post',
                    url: api_uri + "comments/api::post.post:"+this.props.postid,
                    data: body
                }).then((response) => {
                    if (response.status === 200) {
                        alert("The comment is accepted for approval, this could take some time to get processed")
                        this.setState(prevState => ({
                            comments: [...prevState.comments, [response.data.author.name, response.data.content, noPicture, response.data.id]],
                            comment: ""
                        }))
                    } else {
                        alert(response.data.error.message);
                    }
                })
                .catch(error => {
                    let message = JSON.parse(error.response.data.error.message)['message']
                    alert(message);
                });
            } else {
                alert("That's not Vinnies favourite food...")
            }
        } else {
            alert("You submitted an empty comment...");
        }
        event.preventDefault();
    }

    formatDate = (dateTaken) => {
        let momentObject = moment(dateTaken);
        return momentObject.fromNow().toUpperCase();
    }

    componentDidMount() {
        if (!this.props.post.onlylikes) {
            let num = Math.floor(Math.random() * 3) + 1; 
            const res = [];
            for(let i = 0; i < num; ){
                const random = Math.floor(Math.random() * this.props.friends.length);
                if(res.indexOf(this.props.friends[random]) !== -1){
                    continue;
                };
                res.push(this.props.friends[random]);
                i++;
            };

            this.setState({ 
                chosenFriends : res,
                lastFriend: res[res.length -1],
            });
        }

        axios.get(api_uri + "comments/api::post.post:" + this.props.postid + "/flat?filters[approvalStatus][$eq]=APPROVED").then((response) => {
			for (let comment of response.data.data) {
                this.setState(prevState => ({
                    comments: [...prevState.comments, [comment.author.name, comment.content, comment.author.avatar, comment.id]],
                }))
            }
		});

        function hashtag(text) {
            return text ? text.replace(/#(\w+)/g, '<a href="https://instagram.com/explore/tags/$1">#$1</a>') : '';    
        }

        this.props.post.title = hashtag(this.props.post.title)
        this.props.post.translation = hashtag(this.props.post.translation)

    }

    showAll() {
        this.setState(prevState => ({
            showall: !prevState.showall
        }));
    }

    translate(e) {
        this.setState(prevState => ({
            showall: true,
            translated: !prevState.translated
        }));
    }

    render() {
        return (
            <div className="card-footer">
                <div className="card-logos">
                    {this.props.liked ?
                        <img alt="Like Logo" id="heartLogo" className="heart-filled" src={ heartFullLogo } width="23" height="23" onClick={(e) => {this.props.likefunction(e, true, this.props.postid)}}></img>
                        :
                        <img alt="Like Logo" id="heartLogo" src={ heartLogo } width="23" height="23" onClick={(e) => {this.props.likefunction(e, true, this.props.postid)}}></img>
                    }
                    <Link to={"/comments?post=" + this.props.postid }><img alt="Comment Logo" src={ commentLogo } width="23" height="23"></img></Link>
                    <img alt="Share Logo" src={ planeLogo } width="23" height="23"></img>
                    <img alt="Bookmark Logo" src={ bookmarkLogo } width="23" height="23" className="float-right"></img>
                </div>
                {(this.props.post.onlylikes) ?                            
                    <div className="likes">
                        <strong> {this.props.liked? this.props.post.likes + 1 : this.props.post.likes} Likes </strong>
                    </div>
                    :
                    <div className="liked-by-friends">
                        {this.state.chosenFriends.map(friend => (
                            <img 
                                key={friend.id} 
                                alt={friend.attributes.name} 
                                className="rounded-circle post-friend-img" 
                                src={uri + (friend.attributes.gif ? friend.attributes.picture.data.attributes.url : friend.attributes.picture.data.attributes.formats.thumbnail.url)} 
                                width="28" 
                                height="28">
                            </img>
                        ))}
                        Liked by <strong><a href={ this.state.lastFriend.attributes.instaurl }>{ this.state.lastFriend.attributes.name }</a> </strong> and <strong> <span id="likes">{this.props.liked? this.props.post.likes + 1 : this.props.post.likes}</span> others </strong>
                    </div>
                }
                <div className="post-title">
                    <a className="username" href={this.props.user.instaurl}><strong>{this.props.user.username}</strong></a>
                    {this.props.post.title.length < 80 || this.state.showall ? 
                        <span className="post-title-text" ref={this.postTitleText} dangerouslySetInnerHTML={{__html: this.state.translated ? this.props.post.translation : this.props.post.title}}></span> 
                        :
                        <><span className="post-title-text" ref={this.postTitleText} dangerouslySetInnerHTML={{__html: this.props.post.title.slice(0,79) + "... "}}></span><span onClick={(e) => {this.showAll(e)}} className='too-long'>more</span></>
                    }
                </div>
                {this.state.comments.length ? <Link to={"/comments?post=" + this.props.postid } className="view-comments">View all {this.state.comments.length} comments</Link> : ''}
                <div className="date">
                    <small>{this.formatDate(this.props.post.datetaken)}</small>
                    {this.state.translated ?
                        <strong className='translate' onClick={(e) => {this.translate(e)}}> · See Original</strong>
                        :
                        this.props.post.english ? '' : <strong className='translate' onClick={(e) => {this.translate(e)}}> · See Translation</strong>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="comments">
                        {this.state.comments.map(comment => (
                            <div key={comment[3]} className="comment"><img className="rounded-circle comment-picture" alt={comment[0]} src={comment[2]}/><strong>{comment[0]}</strong> {comment[1]}</div>
                        ))}
                        <div className="new-comment">
                            <input type="text" name="comment" placeholder="Add a comment..." value={this.state.comment} onChange={this.handleChange} className="post-comment-input"/>
                            <input type="submit" value="Post" className="post-comment-button"/>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}