import React, { Component} from "react";

export default class DiscoverItem extends Component {
    constructor() {
		super();
		this.state = { 
            
		};
	};

    render() {
        return (
            <a href={"https://zaplog.pro/links/id/" + this.props.post.id} className="discover-item">
                <div className="discover-item-img" alt={this.props.post.id} style={{'background-image': 'url('+this.props.post.image+')' }}/>
            </a>
        )
    }
};
