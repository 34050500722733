import React, { Component } from "react";

import axios from "axios";

import Navbar from './components/navbar'
import LoadingScreen from './components/loading'

import notesLogo from './assets/logos/multiple.png';
import thLogo from './assets/fontawesome/svgs/light/th.svg';
import heartLogo from './assets/fontawesome/svgs/solid/heart.svg';
import commentLogo from './assets/fontawesome/svgs/solid/comment.svg';
import squareLogo from './assets/fontawesome/svgs/light/square.svg';
import playLogo from './assets/fontawesome/svgs/solid/play.svg';
import userLogo from './assets/fontawesome/svgs/regular/user.svg';

import "./css/components/profile.scss"

let uri = process.env.REACT_APP_BASE_URL;
let api_uri = uri + "/api/";

export default class Profile extends Component {
	constructor() {
		super();
		this.state = { 
			posts: [],
			tagged_posts: [],
			user: null,
			loading: true,
			tagged: false,
		};

		this.switchTagged = this.switchTagged.bind(this);
	};

	componentDidMount() {
		axios.get(api_uri + "users/1?populate=*").then(response => {
			this.setState({ 
				user: response.data,
			})

			if (this.state.posts.length > 0 &&
				this.state.tagged_posts.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});

		axios.get(api_uri + "posts?populate=deep,4&filters[uploader][id][$eq]=1&filters[inprofile][$eq]=true&sort=datetaken%3Adesc").then(response => {
			this.setState({ 
				posts: response.data.data,
				loading: false,
			})
			if (this.state.user !== null &&
				this.state.tagged_posts.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});

		axios.get(api_uri + "posts?populate=deep,4&filters[uploader][id][$ne]=1&sort=datetaken%3Adesc").then(response => {
			this.setState({ 
				tagged_posts: response.data.data,
			})

			if (this.state.user !== null &&
				this.state.posts.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});

		setTimeout(() => {
			// console.log(this.state.user)
			// console.log(this.state.posts)
			// console.log(this.state.tagged_posts)
		}, 1000)

		setTimeout(() => {
			this.setState({ 
				loading: false,
			})
		}, 3000)
	}

	switchTagged(select) {
		let posts = document.getElementById('select_posts')
		let tagged = document.getElementById('select_tagged')

		if (select === 'posts') {
			this.setState({
				tagged: false,
			});

			posts.classList.add('active')
			tagged.classList.remove('active')
		} else if (select === 'tagged') {
			this.setState({
				tagged: true,
			});

			posts.classList.remove('active')
			tagged.classList.add('active')
		}
	}

	render() {
		return (
		<>
			{this.state.loading ? 
				<LoadingScreen/>
				:
				<>
					<LoadingScreen fadeout={true}/>
					<Navbar user={this.state.user}></Navbar>
					<div className="container">
						<div className="content-wrapper content-profile">
							<div className="profile">
								<div className="profile-head">
									<div className="profile-photo">
										<img src={uri + this.state.user.picture.formats.small.url} alt="Profile"/>
										<div className="profile-bio mobile">
											<h5><b>{this.state.user.firstname}</b></h5>
										</div>
									</div>
									<div className="profile-info">
										<div className="profile-info-top">
											<div className="profile-name">
												{this.state.user.username}
											</div>
										</div>
										<div className="profile-stats">
											<div className="profile-stats-posts">
												<h4><strong>{this.state.posts.length}</strong> posts</h4>
											</div>
											<div className="profile-stats-followers">
												<h4><strong>{ this.state.user.followers }</strong> followers</h4>
											</div>
											<div  className="profile-numbers-inside">
												<h4><strong>{ this.state.user.following }</strong> following</h4>
											</div>
										</div>
										<div className="profile-bio">
											<h5><b>{this.state.user.firstname} {this.state.user.lastname}</b></h5>
										</div>
									</div>
								</div>
								<div className="selected-posts">
									<div id="select_posts" onClick={() => {this.switchTagged('posts')}} className="select-posts active">
										<img src={ thLogo } width="12" height="14" alt="posts"/>
										<div className="select-text"> <strong>POSTS</strong></div>
									</div>
									{/* <div className="select-reels">
										<img src={ squareLogo } width="18" height="18" alt="square"/><img src={ playLogo } width="9" height="9" alt="reels"/>
										<div className="select-text"> <strong>REELS</strong></div>
									</div> */}
									<div id="select_tagged" onClick={() => {this.switchTagged('tagged')}} className="select-tagged">
										<img src={ squareLogo } width="18" height="18" alt="square"/><img src={ userLogo } width="12" height="10" alt="reels"/>
										<div className="select-text"> <strong>TAGGED</strong></div>
									</div>
								</div>
									{this.state.tagged ?
										<div className="profile-posts">
											{this.state.tagged_posts.map(post => (
												<div key={post.id} className="profile-post">
													{post.attributes.post_images.data[0].attributes.isvideo ?
														<div className="profile-post-image" style={{"backgroundImage": "url(" + uri + post.attributes.post_images.data[0].attributes.video_thumb.data.attributes.thumb.data.attributes.formats.small.url + ")"}}>
															<div className="video-logo">
																<img src={ playLogo } width="13" height="13" alt="multiple"/>
															</div>
															<div className="post-hover">
																<div className="post-likes"><img src={ heartLogo } width="16" height="16" alt="likes"/> <strong>{post.attributes.likes}</strong></div>
																<div className="post-comments"><img src={ commentLogo } width="16" height="16" alt="comments"/> <strong>{post.attributes.likes}</strong></div>
															</div>
														</div>
														:
														<div className="profile-post-image" style={{"backgroundImage": "url(" + uri + post.attributes.post_images.data[0].attributes.postimage.data.attributes.formats.small.url + ")"}}>
															{post.attributes.post_images.data.length > 1 ?
																<div className="multiple-images">
																	<img src={ notesLogo } width="13" height="13" alt="multiple"/>
																</div>
																:
																""
															}
															<div className="post-hover">
																<div className="post-likes"><img src={ heartLogo } width="16" height="16" alt="likes"/> <strong>{post.attributes.likes}</strong></div>
																<div className="post-comments"><img src={ commentLogo } width="16" height="16" alt="comments"/> <strong>{post.attributes.likes}</strong></div>
															</div>
														</div>
													}
												</div>
											))}
										</div>
										:
										<div className="profile-posts">
											{this.state.posts.map(post => (
												<div key={post.id} className="profile-post">
													{post.attributes.post_images.data[0].attributes.isvideo ?
														<div className="profile-post-image" style={{"backgroundImage": "url(" + uri + post.attributes.post_images.data[0].attributes.video_thumb.data.attributes.thumb.data.attributes.formats.small.url + ")"}}>
															<div className="video-logo">
																<img src={ playLogo } width="13" height="13" alt="multiple"/>
															</div>
															<div className="post-hover">
																<div className="post-likes"><img src={ heartLogo } width="16" height="16" alt="likes"/> <strong>{post.attributes.likes}</strong></div>
																<div className="post-comments"><img src={ commentLogo } width="16" height="16" alt="comments"/> <strong>{post.attributes.likes}</strong></div>
															</div>
														</div>
														:
														<div className="profile-post-image" style={{"backgroundImage": "url(" + uri + post.attributes.post_images.data[0].attributes.postimage.data.attributes.formats.small.url + ")"}}>
															{post.attributes.post_images.data.length > 1 ?
																<div className="multiple-images">
																	<img src={ notesLogo } width="13" height="13" alt="multiple"/>
																</div>
																:
																""
															}
															<div className="post-hover">
																<div className="post-likes"><img src={ heartLogo } width="16" height="16" alt="likes"/> <strong>{post.attributes.likes}</strong></div>
																<div className="post-comments"><img src={ commentLogo } width="16" height="16" alt="comments"/> <strong>{post.attributes.likes}</strong></div>
															</div>
														</div>
													}
												</div>
											))}
										</div>
									}
							</div>
						</div>
					</div>
				</>
			}
		</>
		);
	}
}