import React, { useState } from "react";

let uri = process.env.REACT_APP_BASE_URL;

const SlideImage = props => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoadedThumb, setIsLoadedThumb] = React.useState(false);
    const [scale, setScale] = useState(1);
    const [start, setStart] = useState({});
    const [hasFormats] = useState(props.img.postimage.data.attributes.formats.large !== undefined);
    var image = document.createElement("img");
    if (hasFormats) {
        image.src = uri + props.img.postimage.data.attributes.formats.large.url
    } else {
        image.src = uri + props.img.postimage.data.attributes.url
    }
    const [isCached] = useState(image.complete);

    const aspectRatio = props.img.postimageh / props.img.postimagew;
    const aspect = aspectRatio * 100;
    const minHeight = props.width * aspectRatio;

    // Calculate distance between two fingers
    const distance = (event) => {
        return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);
    };

    function touchStartEvent (event) {
        if(event.touches.length === 2) {  
            event.preventDefault()        

            let startTemp = {}
            // Calculate where the fingers have started on the X and Y axis
            startTemp.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
            startTemp.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
            startTemp.distance = distance(event);
            setStart(startTemp);
        }
    }

    function touchMoveEvent(event) {
        if (event.touches.length === 2) {
            event.preventDefault()
            event.stopPropagation()

            let imageElement = event.target
            let scaletmp;

            // Safari provides event.scale as two fingers move on the screen
            // For other browsers just calculate the scale manually
            if (event.scale) {
                scaletmp = event.scale;
            } else {
                const deltaDistance = distance(event);
                scaletmp = deltaDistance / start.distance;
            }

            setScale(Math.min(Math.max(1, scaletmp), 4))

            // Calculate how much the fingers have moved on the X and Y axis
            const deltaX = (((event.touches[0].pageX + event.touches[1].pageX) / 2) - Number(start.x)) * 1.6; // x2 for accelarated movement
            const deltaY = (((event.touches[0].pageY + event.touches[1].pageY) / 2) - Number(start.y)) * 1.6; // x2 for accelarated movement

            // Transform the image to make it grow and move with fingers
            const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${scale})`;

            imageElement.style.transform = transform;
            imageElement.style.WebkitTransform = transform;
            // imageElement.style.zIndex = "9999";
            // imageElement.style.position = "absolute";
        }
    }

    function touchEndEvent(event) {
        let imageElement = event.target
        // Reset image to it's original format
        imageElement.style.transform = "";
        imageElement.style.WebkitTransform = "";
        // imageElement.style.zIndex = "";
        // imageElement.style.position = "";

        setStart({})
    };

    return (
        <div style={{ minHeight : minHeight}}>
            <div style={{ display: isLoadedThumb || isCached ? "none" : "block" , paddingBottom : aspect+"%" }}/>
            {hasFormats ?
                <div style={{ position: "relative" }}>
                    <img
                        onLoad={() => {
                            setIsLoadedThumb(true);
                        }}
                        className="thumb"
                        alt={ props.img.name + " thumbnail" }
                        src={ uri + props.img.postimage.data.attributes.formats.thumbnail.url }
                        style={{ position : "absolute", width : "100%", visibility: isLoaded || isCached ? "hidden" : "visible" }}
                        />
                    <img
                        onLoad={() => {
                            setIsLoaded(true);
                        }}        
                        onTouchStart={touchStartEvent}
                        onTouchMove={touchMoveEvent}
                        onTouchEnd={touchEndEvent}
                        className="full"
                        style={{ opacity: isLoaded || isCached ? 1 : 0, width: "100%" }}
                        alt={ props.img.shorturl }
                        src={ uri + props.img.postimage.data.attributes.formats.large.url }
                        srcSet={`${uri + props.img.postimage.data.attributes.formats.small.url} 500w, ${uri + props.img.postimage.data.attributes.formats.medium.url} 750w, ${uri + props.img.postimage.data.attributes.formats.large.url} 1000w`}
                        />
                </div>
                :
                <div style={{ position: "relative" }}>
                    <img
                        onLoad={() => {
                            setIsLoadedThumb(true);
                        }}
                        className="thumb"
                        alt={ props.img.name + " thumbnail" }
                        src={ props.img.postimage.data.attributes.formats.thumbnail.url ? uri + props.img.postimage.data.attributes.formats.thumbnail.url : uri + props.img.postimage.data.attributes.url }
                        style={{ position : "absolute", width : "100%", visibility: isLoaded || isCached ? "hidden" : "visible" }}
                        />
                    <img
                        onLoad={() => {
                            setIsLoaded(true);
                        }}        
                        onTouchStart={touchStartEvent}
                        onTouchMove={touchMoveEvent}
                        onTouchEnd={touchEndEvent}
                        className="full"
                        style={{ opacity: isLoaded || isCached ? 1 : 0, width: "100%" }}
                        alt={ props.img.shorturl }
                        src={ uri + props.img.postimage.data.attributes.url }
                        />
                </div>
            }
        </div>
    );
};

export default SlideImage;