import React, { Component} from "react";

import DiscoverItem from './discover-item'

export default class DiscoverComponent extends Component {
    constructor() {
		super();
		this.state = { 
            
		};
	};

    render() {
        return (
            <div className="discover-feed">
                {this.props.posts.map(post => (
                    <DiscoverItem post={post}/>
                ))}
            </div>
        )
    }
};
