import React from "react";
import Slider from "react-slick";

import useIntersectionObserver from "../hooks/use-intersection-observer";
import SlideImage from "./slide-image";
import SlideVideo from "./slide-video";

import heartFullLogo from '../assets/fontawesome/svgs/solid/heart.svg';

const PostSlider = props => {
    const ref = React.useRef();    
    const likeRef = React.useRef();
    const [isVisible, setIsVisible] = React.useState(false);
    const [isPaused, setisPaused] = React.useState(false);
    
    useIntersectionObserver({
        target: ref,
        rootMargin: "400px",
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                setisPaused(false)
                if (!props.post.post_images.data[0].attributes.isvideo) {
                    observerElement.unobserve(ref.current);
                }
            } else {
                setisPaused(true)
            }
        }
    });

    const settings = {
        infinite: false,
        slidesToShow: 1,
        dots:true,
        lazyLoad:true,
        arrows:false,
        centerMode: false,
        speed: 650,
        adaptiveHeight: true
    };

    let aspectRatio = 0;
    if (!props.post.isvideo) {
        aspectRatio = (props.post.post_images.data[0].attributes.postimageh / props.post.post_images.data[0].attributes.postimagew) * 100;
    }

    return (
        <div
          href={props.post.id}
          onClick={(e) => {props.likefunction(e, false, props.postid)}}
          ref={ref}
          rel="noopener noreferrer"
          target="_BLANK"
          className={`image-container ${isVisible && 'visible'}`}
          style={{ paddingBottom: isVisible ? "0" : `${aspectRatio}%`, height: isVisible ? "100%" : "0px" }}
          >
            {isVisible && (
                <Slider {...settings}>
                    {props.post.post_images.data.map (img => (
                        img.attributes.isvideo ? 
                            <SlideVideo key={img.id} img={img.attributes} width={props.width} containerRef={ref} likeRef={likeRef} paused={isPaused}/>
                            :
                            <SlideImage key={img.id} img={img.attributes} width={props.width} />
                    ))}
                </Slider>
            )}
            <div className="heart-filled-image" id={"heart_" + props.postid} ref={likeRef}>
                <img alt="Like Logo" id="heartLogo" src={ heartFullLogo } width="100" height="100"></img>
            </div>
        </div>
    );
}


export default PostSlider;