import React, { Component} from "react";

import settingsLogo from '../assets/fontawesome/svgs/light/ellipsis-h.svg';

let uri = process.env.REACT_APP_BASE_URL;

export default class CardHeader extends Component {
    render() {
        return (
            <div className="card-header post-header">
                <>
                    <div className="rounded-circle post-picture">
                        <a href={ this.props.user.instaurl }><img alt="Profile" className="rounded-circle post-picture-img" src={this.props.user.picture.data.attributes.formats ? uri + this.props.user.picture.data.attributes.formats.thumbnail.url : uri + this.props.user.picture.data.attributes.url} width="34" height="34"></img></a>
                    </div>
                    <div className="post-picture-location">
                        <div>
                            <strong><a className="username" href={ this.props.user.instaurl }>{ this.props.user.username } </a></strong>
                            {(this.props.user.username !== 'vincentvdwal')? <><span className="dot"> •</span><a className="follow" href={ this.props.user.instaurl }><strong>Follow</strong></a></> :  '' }
                        </div>
                        {(this.props.post.location)?                            
                            <small> { this.props.post.location } </small>
                            : '' }
                    </div> 
                    <span><img alt="Settings" src={ settingsLogo } width="28" height="28"></img></span>
                </>
            </div>
        )
    }
}