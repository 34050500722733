import React, { Component} from "react";

import unMutedLogo from '../assets/fontawesome/svgs/solid/volume.svg';
import MutedLogo from '../assets/fontawesome/svgs/solid/volume-slash.svg';

let uri = process.env.REACT_APP_BASE_URL;
// let api_uri = uri + "api/";

export default class SlideVideo extends Component {
    constructor() {
		super();
		this.state = {
            loaded: false
        }

        this.setPlaySpeed  = this.setPlaySpeed.bind(this);
        this.videoContainer = React.createRef()
        this.videoRef = React.createRef()
    }

    setPlaySpeed (e) {
        this.setState({
            loaded: true,
        })
        if (this.props.img.playspeed !== '1') {
            e.target.playbackRate = this.props.img.playspeed
        }
      
        if (!this.state.unmuted) {
            let setUnmute = () => {
                if (!this.interactionTimeout) {
                    this.interactionTimeout = setTimeout(() => {
                        e.target.muted = false 
                        e.target.volume = 0.6;
                        e.target.play()
                            .then(() => {
                                this.switchMuted('', true)
                                document.body.removeEventListener("mousemove", setUnmute)
                            })
                            .catch(() => {
                                e.target.muted = true 
                                e.target.play()
                            })
                        this.interactionTimeout = null;
                    }, 1000)
                }
            } 

            document.body.addEventListener("mousemove", setUnmute)
        }
    }

    switchMuted (_, setUnMuted=false) {
        if (setUnMuted) {
            this.setState({ unmuted: true})
            this.videoRef.current.muted = false
        } else {
            this.setState({ unmuted: !this.state.unmuted})
            this.videoRef.current.muted = this.state.unmuted
        }
        this.videoRef.current.volume = 0.6;

        if (!this.state.unmuted) {
            this.mutedLogoImage.current.src = unMutedLogo 
        } else {
            this.mutedLogoImage.current.src = MutedLogo 
        }
    }


    componentDidMount() {
        let imageContainer = this.props.containerRef.current
        // let likeContainer = this.props.likeRef.current
        imageContainer.style.marginTop = '-58px'
        // likeContainer.style.marginTop = '-58px'
        imageContainer.previousElementSibling.classList.add("video")
    } 

    componentDidUpdate () {
        if (this.props.paused) {
            this.videoRef.current.pause()
        } else {
            this.videoRef.current.play()
        }
    }

    render() {
        return <div className="slide-video"> 
            <img
                alt={ this.props.img.name } 
                src={uri + this.props.img.video_thumb.data.attributes.thumb.data.attributes.formats.thumbnail.url}
                style={{ visibility: this.state.loaded ? "hidden" : "visible", width: "100%" }}
                className="image thumb"
            />
            <video
                onLoadedData={(e) => this.setPlaySpeed(e)}
                id={ this.props.id } 
                style={{ opacity: this.state.loaded ? 1 : 0, width: "100%" }}  
                preload="true"
                autoPlay 
                playsInline
                loop
                className="full"
                muted
                ref={this.videoRef}
                >
                <source  src={ uri + this.props.img.postimage.data.attributes.url } type={"video/" + this.props.img.postimage.data.attributes.ext.substring(1, this.props.img.postimage.data.attributes.ext.length)}/>
            </video>
            <div className="mute-button" onClick={this.switchMuted}>
                <img alt="Sound Logo" id="mutedLogo" src={ !this.props.img.muted ? MutedLogo : unMutedLogo } ref={this.mutedLogoImage} width="16" height="16"></img>
            </div>
        </div>
    }
   
};
