import React, { Component } from "react";

import { SizeMe } from 'react-sizeme'
import InfiniteScroll from 'react-infinite-scroll-component';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";

import Card from './components/card'
import Navbar from './components/navbar'
import Sidebar from './components/sidebar'
import Statusbar from './components/statusbar'
import Endmessage from './components/endmessage'
import LoadingScreen from './components/loading'


import './css/components/card.scss';
import './css/components/image.scss';

import './css/darkmode.scss'

let env = process.env
let api_uri = env.REACT_APP_BASE_URL + "/api/";

let darkmode = localStorage.getItem('darkmode');
if (darkmode !== null && darkmode !== 'false') {
	document.body.classList.add('dark')
}

class Home extends Component {
	constructor() {
		super();
		this.state = { 
			posts: [],
			users: [],
			friends: [],
			suggestions: [],
			posts_loaded: [],
			hasMore: true,
			loaded: 0,
			loading: true,
			likes: localStorage.getItem("likes"),
			env: env
		};
	};

	componentDidMount() {
		axios.get(api_uri + "posts?populate=deep,4&sort=datetaken%3Adesc").then((response) => {
			this.setState({ 
				posts: response.data.data,
				posts_loaded: response.data.data.slice(0,3),
				loaded: 3,
			})
			
			if (this.state.users.length > 0 &&
				this.state.friends.length > 0 &&
				this.state.suggestions.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});
		axios.get(api_uri + "suggestions?populate=%2A").then((response) => {
			this.setState({ 
				suggestions: response.data.data,
			})
			if (this.state.posts.length > 0 &&
				this.state.users.length > 0 &&
				this.state.friends.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});
		axios.get(api_uri + "friends?populate=%2A").then((response) => {
			this.setState({ 
				friends: response.data.data,
			})

			if (this.state.posts.length > 0 &&
				this.state.users.length > 0 &&
				this.state.suggestions.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});
		axios.get(api_uri + "users?populate=%2A").then((response) => {
			this.setState({ 
				users: response.data,
			})

			if (this.state.posts.length > 0 &&
				this.state.friends.length > 0 &&
				this.state.suggestions.length > 0) {
				this.setState({ 
					loading: false,
				})
			}
		});

		setTimeout(() => {
			this.setState({ 
				loading: false,
			})
		}, 3000)
	}

	fetchMorePosts = () => {
		setTimeout(() => {
			this.setState({ 
				posts_loaded: this.state.posts.slice(0, this.state.loaded + 3),
				loaded: this.state.loaded + 3,
				hasMore: this.state.posts.length > this.state.loaded + 3
			});
		});
	};

	render() {
		return (
		<>
			{this.state.loading ? 
			<LoadingScreen/>
			:
			<>
				<LoadingScreen fadeout={true}/>
				<Navbar user={this.state.users[0]}></Navbar>
				<div className="container feed">
					<div className="content-wrapper">
						<SizeMe monitorHeight refreshRate={32}>{({ size }) =>
							<div className="content-feed" id="content_feed">
								<Statusbar users={this.state.users}/>
								<InfiniteScroll
									scrollThreshold="600px"
									dataLength={this.state.posts_loaded.length}
									next={this.fetchMorePosts}
									hasMore={this.state.hasMore}
									endMessage={<Endmessage/>}>
									{this.state.posts_loaded.map(post => (
										<Card key={post.id} post={post.attributes} postid={post.id} size={size} user={post.attributes.uploader.data.attributes} friends={this.state.friends} />
									))}
								</InfiniteScroll>
							</div>}
						</SizeMe>
						<Sidebar suggestions={this.state.suggestions} user={this.state.users[0]}/>
					</div>
				</div>
			</>
			}
		</>
		);
	}
}

export default Home;