import React, { useState } from "react";

let uri = process.env.REACT_APP_BASE_URL;

const Image = props => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasFormats] = useState(props.post.post_images.data[0].attributes.postimage.data.attributes.formats.large !== undefined);
    var image = document.createElement("img");
    if (hasFormats) {
        image.src = uri + props.post.post_images.data[0].attributes.postimage.data.attributes.formats.large.url
    } else {
        image.src = uri + props.post.post_images.data[0].attributes.postimage.data.attributes.url
    }
    const [isCached] = useState(image.complete);


    return (
        <div className="outer-image">
            {hasFormats ? 
                <>
                    <img
                        className="image thumb"
                        alt={props.post.shorturl + " thumbnail"}
                        src={uri + props.post.post_images.data[0].attributes.postimage.data.attributes.formats.thumbnail.url}
                        style={{ visibility: isLoaded || isCached ? "hidden" : "visible" }}
                    />
                    <img
                        onLoad={() => {
                            setIsLoaded(true);
                        }}
                        className="image full"
                        style={{ opacity: isLoaded || isCached ? 1 : 0 }}
                        alt={props.post.shorturl}
                        src={uri + props.post.post_images.data[0].attributes.postimage.data.attributes.formats.large.url}
                        srcSet={`${uri +props.post.post_images.data[0].attributes.postimage.data.attributes.formats.small.url} 500w, ${uri +props.post.post_images.data[0].attributes.postimage.data.attributes.formats.medium.url} 750w, ${uri +props.post.post_images.data[0].attributes.postimage.data.attributes.formats.large.url} 1000w`}
                    />
                </>
                :
                <>
                    <img
                        className="image thumb"
                        alt={props.post.shorturl + " thumbnail"}
                        src={ props.post.post_images.data[0].attributes.postimage.data.attributes.formats.thumbnail.url ? uri + props.post.post_images.data[0].attributes.postimage.data.attributes.formats.thumbnail.url : uri + props.post.post_images.data[0].attributes.postimage.data.attributes.url }
                        style={{ visibility: isLoaded || isCached ? "hidden" : "visible" }}
                    />
                    <img
                        onLoad={() => {
                            setIsLoaded(true);
                        }}
                        className="image full"
                        style={{ opacity: isLoaded || isCached ? 1 : 0 }}
                        alt={props.post.shorturl}
                        src={uri + props.post.post_images.data[0].attributes.postimage.data.attributes.url}
                    />
                </>
            }
        </div>
    );
};

export default Image;