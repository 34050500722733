import React, { Component} from "react";

import  CardHeader  from './card-header'
import  CardFooter  from './card-footer'
import  ImageContainer from './image-container'
import  VideoContainer from './video-container'
import  PostSlider from './post-slider'


let likedPosts = JSON.parse(localStorage.getItem('likedposts'))
export default class Card extends Component {
    constructor() {
		super();
		this.state = {
            liked: false
        }

        this.likePost = this.likePost.bind(this);
        this.fadeTimeout = null
    }
    
    likePost(e, click=false, postid) {
        if (e.detail === 2 || click) {
            let oldLiked;
            if (localStorage.getItem('likedposts')) {
                oldLiked =  JSON.parse(localStorage.getItem('likedposts'))
                if (!oldLiked[this.props.postid]) {
                    oldLiked[this.props.postid] = true
                } else {
                    oldLiked[this.props.postid] = !oldLiked[this.props.postid]
                }
            } else {
                oldLiked = {}
                oldLiked[this.props.postid] = true
            }

            this.setState({ 
                liked : oldLiked[this.props.postid],
            });
            localStorage.setItem('likedposts', JSON.stringify(oldLiked))

            if (oldLiked[this.props.postid]) {
                let heart = document.getElementById("heart_" + postid)
                heart.style.opacity = 1;

                if (this.fadeTimeout) {
                    clearTimeout(this.fadeTimeout)
                }

                this.fadeTimeout = setTimeout(() => {
                    heart.style.opacity = 0;
                }, 700)
            }
        }
    }

    componentDidMount() {
        if (likedPosts) {
            if (likedPosts[this.props.postid]) {
                this.setState({ 
                    liked : likedPosts[this.props.postid],
                });
            }
        }
    }

    render() {
        return (
            <div className="card card-post">
                <CardHeader 
                    post={this.props.post}  
                    user={this.props.post.uploader.data.attributes}/>
                {this.props.post.post_images.data.length > 1 ? 
                    <PostSlider 
                        post={this.props.post} 
                        postid={this.props.postid} 
                        width={this.props.size.width - 2} 
                        likefunction={this.likePost}/> 
                    : 
                    this.props.post.post_images.data[0].attributes.isvideo ? 
                        <VideoContainer 
                            post={this.props.post} 
                            postid={this.props.postid} 
                            likefunction={this.likePost}/> 
                        :
                        <ImageContainer 
                            post={this.props.post} 
                            postid={this.props.postid} 
                            likefunction={this.likePost}/> 
                }  
                <CardFooter 
                    postid={this.props.postid} 
                    post={this.props.post} 
                    user={this.props.post.uploader.data.attributes} 
                    friends={this.props.friends} 
                    liked={this.state.liked} 
                    likefunction={this.likePost}/>
            </div>
        )
    }
};